<template>
  <div
    v-if="
      getUser.role.slug === 'charge-de-clientele' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-relation-client'
    "
  >
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button
            variant="primary"
            @click="reLoadDataUnsuccessfulDirectOrdersAction()"
          >
            <div v-if="isReLoadDataUnsuccessfulDirectOrders">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span
              v-else
              class="text-nowrap font-medium-1"
            >Actualiser</span>
          </b-button>
        </b-col>
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :is-loading="isNotCompletedDirectOrdersLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span>
              {{ isNotCompletedDirectOrdersLoading ? "" : "Liste vide" }}
            </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
          </div>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Client -->
          <div
            v-if="props.column.field === 'user'"
            class="align-middle text-center font-small-5"
          >
            <b-media vertical-align="center">
              <span class="d-block text-nowrap text-info font-small-5">
                {{ props.row.user.full_name }}
              </span>
              <span class="text-success font-small-4">{{
                props.row.user.phone_number
              }}</span>
            </b-media>
          </div>

          <!-- Column: Service -->
          <div
            v-else-if="props.column.field === 'recurring_service'"
            class="align-middle text-center font-small-5"
          >
            <span class="font-small-5 text-info">
              {{ props.row.recurring_service.name }}
            </span>
          </div>

          <!-- Column: Budget -->
          <div
            v-else-if="props.column.field === 'budget'"
            class="align-middle text-center font-small-5"
          >
            <span class="font-small-5 text-info">
              {{ props.row.budget }}
            </span>
          </div>

          <!-- Column: Status -->
          <div
            v-else-if="props.column.field === 'status'"
            class="align-middle text-center font-small-5"
          >
            <b-badge
              pill
              :variant="`light-${resolveOrderStatusVariant(
                props.row.status,
                props.row.placement_cost_paid,
                props.row.search_employee
              )}`"
              class="text-capitalize text-nowrap font-medium-1"
            >
              {{
                resolveStatusText(
                  props.row.status,
                  props.row.placement_cost_paid,
                  props.row.search_employee
                )
              }}
            </b-badge>
          </div>

          <!-- Column: Action -->
          <div
            v-else-if="props.column.field === 'action'"
            class="align-middle text-center"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="25"
                    class="text-info align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :id="`takeover${props.row.id}`"
                  class="cursor-pointer itemActions"
                  @click.stop="applyTakeOverAction(props.row)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-1 text-success"
                    size="25"
                  />
                  <span>Prendre en main</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :id="`delete${props.row.id}`"
                  class="cursor-pointer itemActions"
                  @click="applyDeleteFailedDirectOrderAction(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger mr-1"
                    size="25"
                  />
                  <span>Supprimer</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="
            applyLoadMoreNotCompletedDirectOrderAction(metaData.prev_page_url)
          "
        >
          <span
            class="text-white text-nowrap font-medium-2"
          >Page précédente</span>
        </b-button>

        <div>
          <span
            class="text-white font-medium-2"
          >{{ metaData.current_page }} sur
            {{ metaData.last_page }} pages</span>
        </div>

        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="
            applyLoadMoreNotCompletedDirectOrderAction(metaData.next_page_url)
          "
        >
          <span
            class="text-nowrap font-medium-1 text-white"
          >Page suivante</span>
        </b-button>
      </div>
    </b-card>
  </div>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BCard,
  BFormInput,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
  },

  data() {
    return {
      isReLoadDataUnsuccessfulDirectOrders: false,
      tableItems: [],
      dir: false,
      rows: [],
      columns: [
        {
          label: 'Client',
          field: 'user',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher par Client',
            filterFn(data, filterString) {
              return data.full_name
                .toLowerCase()
                .includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Service demandé',
          field: 'recurring_service',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher par Service',
            filterFn(data, filterString) {
              return data.name
                .toLowerCase()
                .includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Budget du Client',
          field: 'budget',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher par Budget',
            filterFn(data, filterString) {
              return data.toLowerCase().includes(filterString.toLowerCase())
            },
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher par Status',
            filterDropdownItems: [
              'Résiliée',
              'En attente',
              'Employé proposé',
              'Non Payé',
              'Contract émis',
              'Contract approuvé',
              'Actif',
              'Terminée',
            ],
            filterFn(data, filterString) {
              return data.toLowerCase() === filterString.toLowerCase()
            },
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],

      avatarText,
      required,

      isTakeOverLoading: false,

      isDataExporting: false,

      fields: {
        // eslint-disable-next-line quote-props
        Nom: 'Nom',
        // eslint-disable-next-line quote-props
        Prénom: 'Prénom',
        // eslint-disable-next-line quote-props
        Téléphone: 'Téléphone',
        'Service demandé': 'Service demandé',
      },

      jsonData: [],
    }
  },

  computed: {
    ...mapGetters('orders', ['getNotCompletedDirectOrders']),
    ...mapGetters(['isNotCompletedDirectOrdersLoading']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getNotCompletedDirectOrders != null) {
        meta.prev_page_url = this.getNotCompletedDirectOrders.prev_page_url
        meta.next_page_url = this.getNotCompletedDirectOrders.next_page_url
        meta.current_page = this.getNotCompletedDirectOrders.current_page
        meta.last_page = this.getNotCompletedDirectOrders.last_page
        meta.current_page_url = `${this.getNotCompletedDirectOrders.path}?page=${this.getNotCompletedDirectOrders.current_page}`
      }
      return meta
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  watch: {
    isNotCompletedDirectOrdersLoading(val) {
      if (val === false) {
        this.rows = this.customTab(this.getNotCompletedDirectOrders.data)
      }
    },
  },

  async created() {
    this.getNotCompletedDirectOrders != null
      ? (this.rows = this.customTab(this.getNotCompletedDirectOrders.data))
      : null

    /* if (this.isNotCompletedDirectOrdersLoading === false) {
      //await this.refetchData()
      const uncompletedOrdersList = this.getNotCompletedDirectOrders;
      // console.log(uncompletedOrdersList)
      await uncompletedOrdersList.forEach((element) => {
        this.jsonData.push({
          // eslint-disable-next-line quote-props
          Nom: element.user.last_name,
          // eslint-disable-next-line quote-props
          Prénom: element.user.first_name,
          // eslint-disable-next-line quote-props
          Téléphone: element.user.phone_number,
          "Service demandé": element.recurring_service.name,
        });
      });
      // console.log(this.jsonData)
    } */
  },

  mounted() {},

  methods: {
    ...mapActions('orders', [
      'takeOverAction',
      'getNotCompletedDirectOrdersAction',
      'getDirectOrdersAction',
      'deleteFailedDirectOrderAction',
      'loadMoreNotCompletedDirectOrderAction',
    ]),

    reLoadDataUnsuccessfulDirectOrdersAction() {
      this.isReLoadDataUnsuccessfulDirectOrders = true
      this.$store.commit('SET_IS_NOT_COMPLETED_DIRECT_ORDERS_LOADING', true)
      this.rows = []
      this.getNotCompletedDirectOrdersAction()
        .then(response => {
          this.isReLoadDataUnsuccessfulDirectOrders = false
          this.$store.commit(
            'SET_IS_NOT_COMPLETED_DIRECT_ORDERS_LOADING',
            false,
          )
        })
        .catch(error => {
          this.isReLoadDataUnsuccessfulDirectOrders = false
          this.$store.commit(
            'SET_IS_NOT_COMPLETED_DIRECT_ORDERS_LOADING',
            false,
          )
        })
    },

    applyLoadMoreNotCompletedDirectOrderAction(url) {
      this.$store.commit('SET_IS_NOT_COMPLETED_DIRECT_ORDERS_LOADING', true)
      this.rows = []
      this.loadMoreNotCompletedDirectOrderAction(url)
        .then(() => {
          this.$store.commit(
            'SET_IS_NOT_COMPLETED_DIRECT_ORDERS_LOADING',
            false,
          )
        })
        .catch(() => {
          this.$store.commit(
            'SET_IS_NOT_COMPLETED_DIRECT_ORDERS_LOADING',
            false,
          )
        })
    },

    resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'danger'
      if (
        status === 0
        && placementCostPaid === false
        && searchEmployee === true
      ) {
        return 'danger'
      }
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },

    resolveStatusText: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'Résilié'
      if (
        status === 0
        && placementCostPaid === false
        && searchEmployee === true
      ) {
        return 'Non Payé'
      }
      if (status === 0) return 'En attente'
      if (status === 1) return 'Employé proposé'
      if (status === 2) return 'Contract émis'
      if (status === 3) return 'Contract approuvé'
      if (status === 4) return 'Actif'
      return 'Terminée'
    },

    customTab(array) {
      const tab = []
      array.forEach(element => {
        if (
          element.status === 0
          && element.placementCostPaid === false
          && element.searchEmployee === true
        ) {
          element.setStatus = 'Non Payé'
        }
        if (element.status === -1) element.setStatus = 'Résilié'
        if (element.status === 0) element.setStatus = 'En attente'
        if (element.status === 1) element.setStatus = 'Employé proposé'
        if (element.status === 2) element.setStatus = 'Contract émis'
        if (element.status === 3) element.setStatus = 'Contrat approuvé'
        if (element.status === 4) element.setStatus = 'Actif'
        if (element.status === 5) element.setStatus = 'Terminée'

        tab.push(element)
      })
      return tab
    },

    applyTakeOverAction(order) {
      this.isTakeOverLoading = true
      const orderId = order.id
      this.$swal({
        title: 'Prise en main',
        text: 'Voulez-vous prendre cette commande en main ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => {
          await this.takeOverAction(orderId)
          await this.applyLoadMoreNotCompletedDirectOrderAction(
            this.metaData.current_page_url,
          )
          return await this.getDirectOrdersAction()
        },
      }).then(async response => {
        if (response.value) {
          this.applyLoadMoreNotCompletedDirectOrderAction(
            this.metaData.current_page_url,
          )
          this.isTakeOverLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: 'Commande prise en main avec succès',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.getDirectOrdersAction()
          this.$router.push({ name: 'admin-orders-recurring' })
        }
      })
    },

    applyDeleteFailedDirectOrderAction(order) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette commande non aboutie ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteFailedDirectOrderAction(order.id)
          return this.applyLoadMoreNotCompletedDirectOrderAction(
            this.metaData.current_page_url,
          )
        },
      })
        .then(response => {
          if (response.value) {
            this.applyLoadMoreNotCompletedDirectOrderAction(
              this.metaData.current_page_url,
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Commande supprimé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>

<style>
.vgt-select {
  background: transparent !important;
  border-color: #404656 !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
